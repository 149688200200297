<template>
  <div class="sub-app location">
    <section class="app-main-toolbar">
      <el-row class="no-margin">
        <h1>{{ $t("page.setting.locationTab.title") }}</h1>
      </el-row>
      <el-row
        type="flex"
        justify="end"
      >
        <el-button
          type="primary"
          :disabled="!isAdmin"
          class="main-actions"
          @click="dialogOpen()"
        >
          <fa-icon icon="plus" /> {{ $t("button.add") }}
        </el-button>
      </el-row>
    </section>
    <section class="app-main-content">
      <data-table
        :data="source.items"
        :total="total"
        :loading="page.loading"
        :config="page.tableProps"
        :show-tool-bar="false"
        @query-change="loadData"
      >
        <el-table-column
          v-for="columnProp in page.tableProps.tableColumnProps"
          :key="columnProp.prop"
          :type="columnProp.type"
          :label="$t(columnProp.label)"
          :column-key="columnProp.columnKey"
          :prop="columnProp.prop"
          :width="columnProp.width"
          :min-width="columnProp.minWidth"
          :sortable="columnProp.sortable"
          :resizable="columnProp.resizable"
          :formatter="columnProp.formatter"
          :align="columnProp.align"
          :header-align="columnProp.headerAlign"
          :class-name="columnProp.className"
          :label-class-name="columnProp.labelClassName"
        >
          <template scope="scope">
            <div
              v-if="columnProp.prop == 'thumbnail'"
              class="ima-cell"
            >
              <data-table-thumbnail
                class="thumbnail"
                :value="scope.row"
              />
            </div>
            <div v-else-if="columnProp.prop == 'altitude'">
              {{ getMinMaxAltitude(scope.row) }}
            </div>
            <div v-else>
              {{ scope.row[columnProp.prop] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label=""
          width="55px"
        >
          <template scope="scope">
            <!-- <el-button-group> -->
            <fa-button
              size="mini"
              :no-border="true"
              icon=""
              class="action-button edit"
              @click="dialogOpen(scope.row.id)"
            />
            <!-- </el-button-group> -->
          </template>
        </el-table-column>
      </data-table>
      <default-dialog
        :show-dialog="groupEditShow"
        :title="
          groupEditMode == 'add'
            ? $t('page.setting.locationTab.dialog.addTitle')
            : $t('page.setting.locationTab.dialog.updateTitle')
        "
        :next-button-text="$t('button.next')"
        :footer-align="groupEditMode == 'edit' ? 'right' : 'center'"
        @close="dialogClose"
        @next="dialogPreSave"
      >
        >
        <template slot="main">
          <el-form
            ref="form"
            :model="form.group"
            label-width="160px"
            :rules="rules"
          >
            <el-form-item
              :label="$t('page.setting.locationTab.dialog.name')"
              prop="locationName"
              class="with_error_hint"
            >
              <el-input v-model="form.group.name" />
            </el-form-item>
            <el-form-item :label="$t('page.setting.locationTab.description')">
              <el-input
                v-model="form.group.comment"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              :label="$t('page.setting.locationTab.dialog.altitude')"
              class="setting-slide-and-box"
            >
              <div class="location-slider-group">
                <div class="start-number">
                  <el-input-number
                    v-model="cached.altitude[0]"
                    :controls="false"
                    @blur="handleChange"
                  />
                </div>
                <div class="slider">
                  <div class="slider__content">
                    <el-slider
                      :key="sliderKey"
                      v-model="cached.altitude"
                      range
                      class="setting-altitude-slider"
                      :min="minGroupAltitude"
                      :max="maxGroupAltitude"
                      :show-tooltip="false"
                    />
                    <label class="hint">{{ groupAlititudeHint }}</label>
                  </div>
                </div>
                <div class="end-number">
                  <el-input-number
                    v-model="cached.altitude[1]"
                    :controls="false"
                    @blur="handleChange"
                  />
                </div>
              </div>
            </el-form-item>

            <el-form-item
              :label="$t('page.setting.locationTab.dialog.rtl_altitude')"
              class="setting-slide-and-box location-rtl-altitude with_error_hint"
              prop="locationRTL"
            >
              <div class="location-slider-group">
                <div class="start-number">
                  <el-input-number
                    v-model="form.group.rtl_altitude"
                    :controls="false"
                  />
                </div>
                <div class="slider">
                  <el-slider
                    v-model="form.group.rtl_altitude"
                    :show-tooltip="false"
                    class="setting-altitude-slider-reverse"
                    :min="minRTLAltitude"
                    :max="maxRTLAltitude"
                  />
                </div>
                <div class="end-number" />
              </div>
            </el-form-item>
            <el-form-item :label="$t('page.setting.locationTab.dialog.alert_detect_user')">
              <el-switch
                v-model="form.group.alerts.detect.people"
                :active-text="$t('page.setting.locationTab.dialog.alert_on')"
                :inactive-text="$t('page.setting.locationTab.dialog.alert_off')"
                active-value="bubble"
                inactive-value="off"
                class="coretronic"
              />
            </el-form-item>
            <el-form-item
              v-if="form.group.stream_config"
              :label="$t('page.setting.locationTab.streamingServer')"
            />

            <el-form-item
              v-if="form.group.stream_config"
              class="whiteBody"
            >
              <div style="margin-left: -38px;">
                <el-row />
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.real_ip") }}</Label>
                  <el-input
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.real_ip"
                    class="streaming"
                  />
                </el-row>
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.api_port") }}</Label>
                  <el-input-number
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.api_port"
                    :controls="false"
                    class="streaming"
                  />
                </el-row>
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.port") }}</Label>
                  <el-input-number
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.port"
                    :controls="false"
                    class="streaming"
                  />
                </el-row>
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.ip") }}</Label>
                  <el-input
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.ip"
                    class="streaming"
                  />
                </el-row>
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.webrtc_port") }}</Label>
                  <el-input-number
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.webrtc_port"
                    :controls="false"
                    class="streaming"
                  />
                </el-row>
                <el-row>
                  <Label>{{ $t("page.setting.locationTab.account") }}</Label>
                  <el-input
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.account"
                    class="streaming"
                  />
                </el-row>
                <el-row class="margin_btm">
                  <Label>{{ $t("page.setting.locationTab.password") }}</Label>
                  <el-input
                    v-if="form.group.stream_config"
                    v-model="form.group.stream_config.password"
                    type="password"
                    class="streaming"
                  />
                </el-row>
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template slot="footer">
          <el-button
            v-if="groupEditMode == 'edit'"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="deleteShow = true"
          >
            {{ $t("button.delete") }}
          </el-button>
        </template>
      </default-dialog>

      <default-dialog
        :show-dialog="deleteShow"
        :center="true"
        :body-center="true"
        :next-button-text="$t('page.setting.locationTab.deleteConfirm.delete')"
        footer-align="center"
        @close="deleteShow = false"
        @next="deleteAction"
      >
        <template slot="main">
          <h2>{{ $t("page.setting.locationTab.deleteConfirm.title") }}</h2>
          <p>{{ $t("page.setting.locationTab.deleteConfirm.body") }}</p>
        </template>
      </default-dialog>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { DisMeasure } from '@/utils/measure';
import DefaultDialog from '@/components/DefaultDialog';
import tableProps from '@/config/tableProps/locationList';
import DataTable from '@/components/DataTable/DataTable';
import FaButton from '@/components/FaButton';
import GroupApi from '@/services/api/domain/group';
import DataTableThumbnail from '@/components/DataTable/Thumbnail';

export default {
  name: 'LocationSubApp',
  components: {
    DataTable,
    DefaultDialog,
    FaButton,
    DataTableThumbnail,
  },
  data() {
    const validateName = (rule, value, callback) => {
      const { id, name } = this.form.group;
      const nameEmpty = 'page.setting.locationTab.nameEmpty';
      const nameDuplicated = 'page.setting.locationTab.nameDuplicated';

      if (name == null || name == '') {
        callback(new Error(this.$i18n.t(nameEmpty)));
        return;
      }
      let duplicated = false;
      if (id != null) {
        duplicated = this.source.items.find((group) => group.id != id && group.name == name) != null;
      } else {
        duplicated = this.source.items.find((group) => group.name == name) != null;
      }
      if (duplicated) {
        callback(new Error(this.$i18n.t(nameDuplicated)));
      } else {
        callback();
      }
    };
    const validateRTL = (rule, value, callback) => {
      const { altitude, rtl_altitude } = this.form.group;
      const nameDuplicated = 'page.setting.locationTab.rtlOutOfRange';

      if (altitude == null || rtl_altitude == null || altitude.length < 2) {
        callback(new Error(this.$i18n.t(nameDuplicated)));
        return;
      }
      const outRange = altitude[0] > rtl_altitude || altitude[1] < rtl_altitude;
      if (outRange) {
        callback(new Error(this.$i18n.t(nameDuplicated)));
      } else {
        callback();
      }
    };
    return {
      deleteShow: false,
      filter: {},
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      page: {
        loading: false,
        tableProps,
      },
      form: {
        group: CopyObject(this.$store.state.group.group),
      },
      total: 0,
      rules: {
        locationName: [
          { validator: validateName, trigger: 'change' },
        ],
        locationRTL: [
          { validator: validateRTL, trigger: 'none' },
        ],
      },
      sliderIndex: 0,
      cached: {
        altitude: [0, 0],
        rtlAltitude: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      groups: 'user/groups',
      groupEditData: 'group/getData',
      groupEditShow: 'group/shouldShow',
      groupEditMode: 'group/getMode',
      isAdmin: 'user/isAdmin',
      domainCenter: 'user/location',
    }),
    minRTLAltitude() {
      return DisMeasure.display(15, 0, 0, 'ceil');
    },
    maxRTLAltitude() {
      return DisMeasure.display(120, 0, 0, 'floor');
    },
    minGroupAltitude() {
      return DisMeasure.display(-100, 0, 0, 'ceil');
    },
    maxGroupAltitude() {
      return DisMeasure.display(120, 0, 0, 'floor');
    },
    groupAlititudeHint() {
      return `${this.minGroupAltitude} ~ ${this.maxGroupAltitude} ${DisMeasure.unit()}`;
    },
    sliderKey() {
      return `sliderKey-${this.sliderIndex}`;
    },
  },
  created() {
    if (this.$router.currentRoute.params.clear) {
      this.setGroupEditMode(null);
    }
    this.cached.altitude[0] = DisMeasure.display(this.form.group.altitude[0]);
    this.cached.altitude[1] = DisMeasure.display(this.form.group.altitude[1]);
    this.cached.rtlAltitude = DisMeasure.display(this.form.group.rtl_altitude);
  },
  async mounted() {
    if (this.groups != null) {
      this.total = this.groups.length;
    }

    if (this.groups == null || this.groups.length === 0) this.dialogOpen();
    if (this.groups != null && this.groups.length === 1) {
      await this.setGroupId(this.groups[0].id);
    }
  },
  methods: {
    async loadData(filter) {
      if (filter && filter.sort != null && filter.sort.prop != null) {
        this.filter.sort = `${filter.sort.prop}${filter.sort.order === 'descending' ? ':des' : ''}`;
      } else {
        this.filter.sort = null;
      }

      try {
        this.page.loading = true;
        const { data } = await GroupApi.getAll(this.filter);
        this.source.items = data.groups;
      } catch (error) {
        this.$showFail(error);
      }
      this.page.loading = false;
    },
    dialogOpen(groupId) {
      if (groupId) {
        const data = this.source.items.find((group) => group.id === groupId);
        this.importGroupDataToEdit(CopyObject(data));
        this.form.group = CopyObject(this.groupEditData);
      } else {
        this.setGroupEditMode('add');
        this.form.group = CopyObject(this.groupEditData);
        this.form.group.latitude = this.domainCenter.latitude;
        this.form.group.longitude = this.domainCenter.longitude;
      }
      this.cached.altitude[0] = DisMeasure.display(this.form.group.altitude[0]);
      this.cached.altitude[1] = DisMeasure.display(this.form.group.altitude[1]);
      this.cached.rtlAltitude = DisMeasure.display(this.form.group.rtl_altitude);
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    dialogClose() {
      this.setGroupEditMode(null);
    },
    dialogSave() {
      this.form.group.altitude[0] = DisMeasure.real(this.cached.altitude[0], 2);
      this.form.group.altitude[1] = DisMeasure.real(this.cached.altitude[1], 2);
      this.form.group.rtl_altitude = DisMeasure.real(this.cached.rtl_altitude, 2);
      this.setGroupEditData(this.form.group);
      if (this.groupEditMode === 'add') {
        this.$router.push({
          name: 'createLocationMap',
        });
      } else {
        this.$router.push({
          name: 'editLocationMap',
        });
      }
    },
    dialogPreSave() {
      if (this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogSave();
          return true;
        }
        return false;
      })
      );
    },
    getMinMaxAltitude(item) {
      const min = DisMeasure.display(item.min_altitude, 0, 0, 'round');
      const max = DisMeasure.display(item.max_altitude, 0, 0, 'round');
      return `${min} ~ ${max} ${DisMeasure.unit()}`;
    },
    handleChange() {
      this.sliderIndex += 1;
      if (this.sliderIndex > 100) {
        this.sliderIndex = 0;
      }
    },
    async deleteAction() {
      const groupId = this.groupEditData.id;
      const loading = this.$loading({
        lock: true,
      });

      try {
        this.deleteShow = false;
        this.setGroupEditMode(null);

        const status = await GroupApi.delete(groupId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.updateGroupData();
      this.loadData();
      loading.close();
    },
    ...mapActions({
      setGroupEditMode: 'group/setEditMode',
      setGroupEditData: 'group/setData',
      importGroupDataToEdit: 'group/importData',
      updateGroupData: 'user/getGroups',
      setGroupId: 'user/setGroupId',
    }),
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  height: 84px;
  min-height: 84px;
  min-width: 124px;
  border: solid 1px #909399;
}
</style>

<style lang="scss">
// .sub-app.location{
// }

.el-slider.setting-altitude-slider {
  margin-left: 10px;
  margin-right: 10px;
  .el-slider__runway {
    background-color: #d8d8d8;
  }

  .el-slider__bar {
    background-color: #ffc900;
  }
  .el-slider__button {
    width: 22px;
    height: 22px;
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.el-slider.setting-altitude-slider-reverse {
  margin-left: 10px;
  margin-right: 10px;
  .el-slider__runway {
    background-color: #ffc900;
  }

  .el-slider__bar {
    background-color: #d8d8d8;
  }
  .el-slider__button {
    width: 22px;
    height: 22px;
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.setting-slide-and-box {
  input.el-input__inner {
    text-align: center;
    width: 60px;
  }
}

.location-slider-group {
  display: flex;
  .start-number,
  .end-number {
    width: 56px;
  }
  .slider {
    flex-grow: 1;
    margin: 0 20px;
    .slider__content {
      label.hint {
        text-align: center;
        color: #838383;
        height: 16px;
        line-height: 16px;
        width: 100%;
        display: inline-block;
      }
    }
  }
}
</style>
